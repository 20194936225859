<template>
    
  <div v-if="user">
    <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      ref="commentField"
      v-model="newMessage"
      :label="$t('results.comments.comment-label')"
      type="text"
      no-details
      outlined
      :append-outer-icon="posting && $store.getters.isLoading ? 'fa fa-circle-notch fa-spin' : 'fa-paper-plane'"
      :rules="rules"
      :loading="posting && $store.getters.isLoading"
      :disabled="posting && $store.getters.isLoading"
      @keydown.enter.prevent="postCommentOnItem"
      @click:append-outer="postCommentOnItem"
      hide-details
    />
    </v-form>
  </div>
       
</template>

<script>
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "PostCommentField",
  components: {
  },
  props: {
  },
  data: function() {
    return {
      newMessage: null,
      posting: false,
      valid: true,
      rules: [
        v => !!v || "Please enter the comment you want to post.",
      ],
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    async postCommentOnItem() {
      if (this.$refs.form.validate() && this.user && this.newMessage) {
        this.posting = true;
        this.$emit('submit', this.newMessage);
        //this.newMessage = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  watch: {
  }
};
</script>

<style lang="scss">
</style>